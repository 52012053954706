export default function () {
	return {
		get authorizedCmsEditPageList() {
			const pageList = [
				"our-mission-edit",
				"about-us-edit",
				"history-edit",
				"collegiality-statement-edit",
				"diversity-edit",
				"governance-committee-edit",
				"board-of-trustees-edit",
				"human-resources-advisory-committee-edit",
				"scientific-advisory-committee-edit",
				"semester-programs-edit",
				"workshops-edit",
				"colloquia-seminars-edit",
				"adjoint-edit",
				"past-semester-programs-edit",
				"past-workshops-edit",
				"past-colloquia-seminars-edit",
				"msri-up-edit",
				"summer-graduate-schools-edit",
				"summer-research-in-mathematics-edit",
				"news-and-events-edit",
				"media-edit",
				"media-create",
				"page-builder",
				"page-builder-edit",
				"staff-dashboard",
				"adjoint-admin",
				"participants-preview",
				"adjoint-participant-survey",
				"quick-link-builder",
				"publications-create",
				"publications-edit",
				"documentaries-and-public-films-create",
				"documentaries-and-public-films-edit",
				"press-release-archive-create",
				"press-release-archive-edit",
				"public-understanding-of-math-create",
				"public-understanding-of-math-edit",
				"message-banner",
				"support-edit",
				"goverment-sponsors-edit",
				"private-sponsors-edit",
				"public-sponsors-edit",
				"current-donors-edit",
				"museion-society-edit",
				"donate-edit",
				"page-content-edit",
				"emissary-news-create",
				"ck-editor-image-upload",
				"ck-editor-file-upload",
				"schedule-create-video",
				"schedule-edit-video",
				"user-list",
				"whitelist-emails",
				"url-mapper",
				"folder-image-logs",
				"page-revision",
				"schedule-create-supplement",
				"schedule-edit-supplement",
				"calendar-all-events",
				"general-events-agenda-create",
				"general-events-agenda-edit",
				"exit-survey",
				"user-spam-list",
				"x11-forwarding"
			]
			return pageList
		},
	}
}
