<template>
  <div>
    <div class="banner-sec" v-if="showBanner">
      <b-card class="px-3 py-1" text-variant="black">
        <span><i class="fa fa-info-circle"></i></span>
        <span class="mr-3 regular-16">{{bannerData.banner_title}}</span>
        <span class="regular-14" v-html="bannerData.banner_desc"/>
      </b-card>
      <div class="close-banner-btn" @click="closeBanner()">
        <i class="ion ion-md-close-circle-outline" v-b-tooltip.hover title="Close"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageBanner',
  props: ['bannerData'],
  data () {
    return {
      showBanner: true
    }
  },
  methods: {
    closeBanner () {
      this.showBanner = false
    }
  }
}
</script>
<style scoped lang="scss">
@media screen and (min-width: 600px) {
  h6 { padding-top: 19px; }
}
@media screen and (max-width: 600px) {
  h6 { padding-top: 5px; margin: 0; }
}
img{
  width: 200px;
}
.card-body{
  text-align: center;
  margin-left: 0.7rem;
  padding: 0.5rem;
}
.banner-sec {
  position: relative;
  background-color: #FCFAF8;
  .close-banner-btn {
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    margin: auto;
    height: 24px;
    font-weight: bold;
    font-size: 1.3em;
    color: #4E5155;
    cursor: pointer;
  }
}
</style>
