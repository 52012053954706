export function scrollToTop() {
	window.scrollTo(0, window.innerHeight - 1)
}

export function scrollToElementId(selectedId) {
	if (selectedId !== undefined && selectedId != null) {
		const elmnt = document.getElementById(selectedId)
		if (elmnt !== undefined && elmnt != null) {
			elmnt.scrollIntoView()
		}
	}
}

export function encodeUrl(str) {
	return window.btoa(str)
}

export function customViewerOptions() {
	const viewerOptions = {
		tooltip: true,
		zoomable: true,
		transition: true,
		keyboard: true,
		toolbar: true,
		title: false,
		movable: true,
		slideOnTouch: true,
		navbar: false,
	}
	return viewerOptions
}

export function ckeditorCustomToolbar() {
	const toolBar = {
		extraPlugins: "mathjax",
		mathJaxLib: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
		toolbar: [
			{
				name: "document",
				groups: ["mode", "document", "doctools"],
				items: ["Source", "-", "Save", "NewPage", "Preview", "Print", "-", "Templates"],
			},
			{
				name: "clipboard",
				groups: ["clipboard", "undo"],
				items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"],
			},
			{
				name: "editing",
				groups: ["find", "selection", "spellchecker"],
				items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
			},
			{
				name: "forms",
				items: ["Form", "Checkbox", "Radio", "TextField", "Textarea", "Select", "Button", "ImageButton", "HiddenField"],
			},
			"/",
			{
				name: "basicstyles",
				groups: ["basicstyles", "cleanup"],
				items: [
					"Bold",
					"Italic",
					"Underline",
					"Strike",
					"Subscript",
					"Superscript",
					"-",
					"CopyFormatting",
					"RemoveFormat",
				],
			},
			{
				name: "paragraph",
				groups: ["list", "indent", "blocks", "align", "bidi"],
				items: [
					"NumberedList",
					"BulletedList",
					"-",
					"Outdent",
					"Indent",
					"-",
					"Blockquote",
					"CreateDiv",
					"-",
					"JustifyLeft",
					"JustifyCenter",
					"JustifyRight",
					"JustifyBlock",
					"-",
					"BidiLtr",
					"BidiRtl",
					"Language",
				],
			},
			{ name: "links", items: ["Link", "Unlink", "Anchor"] },
			{
				name: "insert",
				items: ["Image", "Flash", "Table", "HorizontalRule", "Smiley", "SpecialChar", "PageBreak", "Iframe", "Mathjax"],
			},
			"/",
			{ name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
			{ name: "colors", items: ["TextColor", "BGColor"] },
			{ name: "tools", items: ["Maximize", "ShowBlocks"] },
			{ name: "others", items: ["-"] },
			{ name: "about", items: ["About"] },
		],
		height: 300,
	}
	return toolBar
}

export function getYouTubeEmbededCode(url) {
	const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
	const match = url.match(regExp)

	if (match && match[2].length === 11) {
		return `//www.youtube.com/embed/${match[2]}`
	}
	return "error"
}
