<template>
  <div>
    <div class="container pt-5">
      <b-card class="col-lg-6 col-md-12 m-auto border">
        <b-form autocomplete="off" @submit="onSubmit">
          <h3 class="text-center mt-3 mb-5 lyon-display-medium-24">Create Your SLMath Account</h3>

          <b-form-group>
            <b-input
              v-model="signupForm.firstName"
              :class="{'is-invalid': $v.signupForm.firstName.$error, 'is-valid': !$v.signupForm.firstName.$invalid}"
              placeholder="First Name*"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.signupForm.firstName.required">
              First name is required.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.signupForm.firstName.isNameValid">
              Please enter a valid First name. Names can only contain letters, spaces, underscores, and dots.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-input
              v-model="signupForm.lastName"
              :class="{'is-invalid': $v.signupForm.lastName.$error, 'is-valid': !$v.signupForm.lastName.$invalid}"
              placeholder="Last Name*"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.signupForm.lastName.required">
              Last name is required.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.signupForm.lastName.isNameValid">
              Please enter a valid Last name. Names can only contain letters, spaces, underscores, and dots.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-input
              v-model="signupForm.email"
              type="email"
              maxlength="50"
              :class="{'is-invalid': $v.signupForm.email.$error, 'is-valid': !$v.signupForm.email.$invalid}"
              placeholder="Email Address*"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.signupForm.email.required || !$v.signupForm.email.email">
              Please provide a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-input
              v-model="signupForm.password"
              :class="{'is-invalid': $v.signupForm.password.$error, 'is-valid': !$v.signupForm.password.$invalid}"
              type="password"
              placeholder="Password*"
              maxlength="20"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.signupForm.password.required || !$v.signupForm.password.minLength || !$v.signupForm.password.maxLength">
              Password must be between 6 and 20 characters long.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-else-if="!$v.signupForm.password.pswValid">
              Please enter valid password.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-input
              v-model="signupForm.repassword"
              :class="{'is-invalid': $v.signupForm.repassword.$error, 'is-valid': !$v.signupForm.repassword.$invalid}"
              type="password"
              placeholder="Repeat Password*"
              maxlength="20"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.signupForm.password.sameAs">
              Enter same password.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <div id="recaptcha" />
          </b-form-group>

          <b-button type="submit" variant="primary" :disabled="!recaptchaVerified || loading" block>
            <b-spinner v-if="loading" /> SIGN UP NOW
          </b-button>
        </b-form>
        <div class="mt-3 mb-3 text-center d-flex justify-content-center">
          <button type="button" class="nav-link p-2" @click="openLoginModal">
            <span class="typo-color-blue">
              <i class="fas fa-user" />
              Have an Account? Login Now
            </span>
          </button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { required, email, sameAs, minLength, maxLength, helpers } from "vuelidate/lib/validators"

export default {
	name: "Signup",
	metaInfo: {
		title: "Sign up",
	},
	data() {
		return {
			signupForm: {
				firstName: "",
				lastName: "",
				email: "",
				password: "",
				repassword: "",
			},
			token: "",
			recaptchaVerified: false,
			loading: false,
		}
	},
	validations: {
		signupForm: {
			firstName: {
				required,
				isNameValid: helpers.regex("isNameValid", /^[a-z\-_. ]+$/i),
			},
			lastName: {
				required,
				isNameValid: helpers.regex("isNameValid", /^[a-z\-_. ]+$/i),
			},
			email: {
				required,
				email,
			},
			password: {
				required,
				minLength: minLength(6),
				maxLength: maxLength(20),
				pswValid: helpers.regex("pswValid", /^\S*$/),
			},
			repassword: {
				required,
				sameAsPassword: sameAs("password"),
			},
		},
	},
	mounted() {
		this.loadRecaptcha()
	},
	methods: {
		openLoginModal() {
			this.$root.$emit("hideSignUpModal")
			this.$root.$emit("openSigninModal")
		},
		async loadRecaptcha() {
			await grecaptcha.ready(() => {
				grecaptcha.execute(process.env.VUE_APP_RECAPTCHA, { action: "signup" }).then((token) => {
					this.token = token
					this.recaptchaVerified = true
				})
			})
		},
		async onSubmit(event) {
			event.preventDefault()
			this.$v.$touch()
			this.loadRecaptcha()
			if (this.$v.$invalid) {
				this.$toasted.global.error("Please fill out the required fields.")
			} else {
				if (this.recaptchaVerified) {
					this.loading = true
					const response = await this.$store.dispatch("signUp", {
						user: {
							first_name: this.signupForm.firstName,
							last_name: this.signupForm.lastName,
							email: this.signupForm.email,
							password: this.signupForm.password,
						},
						recaptchaToken: this.token,
					})
					if (response && response.status === 200) {
						this.$root.$emit("hideSignUpModal")
						this.$root.$emit("openSignUpAlertModal")
					}
					this.loading = false
				}
			}
		},
	},
}
</script>

<style scoped lang='scss'>
  .nav-link {
    border: none;
    background: none;
    color: black;
    padding: 0 0;
  }
</style>
