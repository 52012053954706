<template>
  <nav class="layout-footer footer" :class="getLayoutFooterBg()" v-if="!loadingFooterData && footerData">
    <div class="py-3 overlay">
      <div class="container">
        <div v-if="canEditCms" style="float: right;">
          <i class="fa fa-edit dark-icon" v-b-tooltip.hover title="Edit" v-b-modal="'footerModal'"></i>
        </div>
      </div>
    </div>
    <div class="container pb-3 pt-5 medium-21">
      <div class="row">
        <div class="col-md-4 mb-4">
          <img class="logo" src="/img/SLMath/SLMath_Primary_Lockup_White.png" />
          <div
            class="mt-4 regular-12 typo-color-white custom-html-text"
            v-html = "footerData.footer_support"
          />
          <div>
            <router-link :to="{ name: 'donate' }">
              <button class="mt-4 btn btn-primary btn-md"><b>DONATE NOW</b></button>
            </router-link>
          </div>
        </div>
        <div class="col-md-3 mb-4 typo-color-white">
          Contact Us
          <div class="d-flex medium-14 typo-color-white mb-2 mt-4">
            <img class="icon" :src="getMapIcon" />
            <span>
              {{footerData.footer_contact_address}}
            </span>
          </div>
          <div class="d-flex medium-14 typo-color-white mb-2">
            <img class="icon" :src="getPhoneIcon" />
            <span>
              Phone: {{footerData.footer_contact_phone}} <br>
              Fax: {{footerData.footer_contact_fax}}
            </span>
          </div>
          <div class="d-flex medium-14 typo-color-white mb-2">
            <img class="icon" :src="getEmailIcon" />
            <span>{{footerData.footer_contact_mail_address}}</span>
          </div>
        </div>
        <div class="col-md-3 mb-4 typo-color-white">
          <div class="mb-3">Quick Links</div>
          <ul class="custom-quick-link-padding">
            <li v-for="(quickLink, index) in footerData.footer_quick_link" :key="index" class="bold-14 typo-color-white mb-1">
              <a :href="quickLink.menu.path">
                <span class="typo-color-white">{{quickLink.menu.name}}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-2 mb-4 typo-color-white">
          {{footerData.footer_mailing_title}}
          <div
            class="mt-4 mb-2 medium-14 typo-color-white custom-html-text"
            v-html = "footerData.footer_mailing_text"
          />
          <!-- <div class="dm-sans-bold-14 typo-color-white">JOIN NOW</div> -->
          <a class="" :href="footerData.footer_mailing_join_url">
            <span class="bold-14 typo-color-white">{{footerData.footer_mailing_join_label}}</span>
          </a>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 d-flex nsf-logo-section mb-2">
          <img class="nsf-logo" :src="footerLogo" />
        </div>
        <div class="col-md-6 d-flex social-sites-section">
          <span class='social-sites-btn mr-3' @click="applyButtonRedirect(footerData.footer_facebook_url)">
            <i class="ion d-block ion-logo-facebook"/>
          </span>
          <span class='social-sites-btn' @click="applyButtonRedirect(footerData.footer_twitter_url)">
            <i class="ion d-block ion-logo-twitter"></i>
          </span>
          <span class='social-sites-btn' @click="applyButtonRedirect(footerData.footer_instagram_url)">
            <i class="ion d-block ion-logo-instagram"/>
          </span>
        </div>
      </div>

      <div class="copyright-section mt-2">
        <span class="medium-12 mr-4 typo-color-white">{{footerData.footer_copyright_text}}</span>
        <a
          class="medium-12"
          style="z-index: 1;"
          :href="footerData.footer_privacy_policy_url"
        >
          <span class="typo-color-white">Privacy Policy</span>
        </a>
      </div>
    </div>
    <b-modal
      ref="footerModal"
      id="footerModal"
      size="lg"
      title="Edit Footer"
      centered
      hide-footer
      no-enforce-focus
      no-close-on-backdrop
      no-close-on-esc
    >
      <footer-edit />
    </b-modal>
  </nav>
  <!-- <custom-loader v-else/> -->
</template>

<script>
import MapIcon from "../assets/images/icons/ic20-map.png"
import PhoneIcon from "../assets/images/icons/ic20-phone.png"
import EmailIcon from "../assets/images/icons/ic20-email.png"
import { mapGetters } from "vuex"
import FooterEdit from "../components/CommonPages/FooterEdit.vue"
//import CustomLoader from '@/components/CommonPages/CustomLoader.vue'
import mixin from "@/utils/mixin.js"

export default {
	name: "app-layout-footer",
	components: { FooterEdit },
	mixins: [mixin],
	data() {
		return {
			footerData: null,
			loadingFooterData: true,
			footerLogo: "",
		}
	},
	created() {
		this.loadFooterData()
	},
	mounted() {
		this.$root.$on("footerModalEvent", () => {
			this.$refs.footerModal.hide()
			this.loadFooterData()
		})
	},
	computed: {
		...mapGetters(["canEditCms"]),
		getMapIcon() {
			return MapIcon
		},
		getPhoneIcon() {
			return PhoneIcon
		},
		getEmailIcon() {
			return EmailIcon
		},
	},
	methods: {
		getLayoutFooterBg() {
			return `bg-${this.layoutFooterBg}`
		},
		loadFooterData() {
			this.loadingFooterData = true
			this.$http
				.get(`${process.env.VUE_APP_API_URL}/footer_pages`)
				.then((res) => {
					if (res) {
						this.footerData = res.data.footer_page
						this.footerLogo = res.data.image_url
					}
				})
				.finally(() => {
					this.loadingFooterData = false
				})
		},
	},
}
</script>

<style scoped lang="scss">
.logo{
  max-width: 14rem;
}
.footer.bg-dark{
  background: #000000 !important;
  color: #FFFFFF;
}
.icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: .5rem;
}
.nsf-logo {
  width: auto;
  min-height: 96px;
  height: 3rem;
}
.social-sites-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: #FFFFFF;
  border-radius: 1.25rem;
  cursor: pointer;

  &:hover {
    i {
      color: #EB5A23;
    }
  }
  &:last-child {
    margin-left: 1rem;
  }
  i {
    font-size: 1.25rem;
    color: #21201F;
    transition: all .3s ease;
  }
}
.copyright-section {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .copyright-section {
    flex-direction: column;
  }
  .social-sites-section {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .nsf-logo-section {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
.custom-quick-link-padding{
  padding: 0px;
  @media (max-width: 64rem) {
    padding: 0px 20px !important;
  }
}

</style>
