<template>
  <div class="layout-navbar">
    <div class="container">
      <b-navbar toggleable="lg" class="navbar-expand-lg">
        <b-navbar-brand to="/">
          <img class="logo" src="/img/SLMath/SLMath_Primary_Lockup_SVG.svg" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <template v-if="screenSize <= 991">
            <SubMenu class="nav-sub-menu" />
            <MainMenu class="ml-auto mb-3"/>
            <search :type="'dashboard'"/>
          </template>
          <template v-if="screenSize > 991">
            <MainMenu class="ml-auto"/>
          </template>
        </b-collapse>
      </b-navbar>

      <div class="sub-menu desktop-only">
        <div class="row">
          <div class="col-4 ml-auto">
            <search :type="'dashboard'"/>
          </div>
        </div>
        <b-navbar toggleable="lg" class="pb-0">
          <b-navbar-brand to="/" class="m-0">
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse2"></b-navbar-toggle>

          <b-collapse id="nav-collapse2" is-nav>
            <template v-if="screenSize > 991">
              <SubMenu class="nav-sub-menu"/>
            </template>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from '@/components/header/MainMenu'
import SubMenu from '@/components/header/SubMenu'
import Search from '@/components/Search'

export default {
  name: 'app-layout-navbar',
  
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      screenSize: window.innerWidth
    }
  },
  components: {
    SubMenu,
    MainMenu,
    Search
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize)
  },
  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },
    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },
    updateScreenSize() {
      this.screenSize = window.innerWidth
    }
  }
}
</script>

<style lang="scss">
.layout-navbar {
  .navbar{
    padding-left: 0;
    padding-right: 0;
  }
  .logo{
    max-width: 14rem;
  }
  .nav-link {
    outline: none;
  }
  .nav-button a {
    padding: 0;
  }
  .dropdown-item {
    padding: 0.2rem 1.25rem !important;
    color: #000000 !important;
  }
  .dropdown-item:not(.disabled).active,
  .dropdown-item:not(.disabled):active {
    background-color: #FFFFFF;
    color: #000000;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    text-decoration: none;
    background-color: #FFFFFF;
    color: #000000;
  }

  .navbar-light .nav-link {
    color: #000000;
  }

  .navbar-light .nav-sub-menu .nav-link {
    border-bottom: 2px solid transparent;
    color: #000000 !important;
  }
  .navbar-light .nav-sub-menu .nav-link:hover,
  .navbar-light .nav-sub-menu .nav-link:focus,
  .nav-item.b-nav-dropdown.dropdown.show .nav-link,
  .nav-item.b-nav-dropdown.dropdown:hover .nav-link {
    border-color: #666 !important;
  }
  .nav-item.b-nav-dropdown.dropdown .nav-link::after {
    display:none;
  }

  .nav-item.b-nav-dropdown.dropdown {
    position: initial;
    cursor: pointer !important;
  }
  .dropdown-menu {
    width: 100%;
    margin-top: 0;
  }

  // Always keep media query in the last
  // for mobile
  @media only screen and (max-width: 991px) {
    .desktop-only {
      display: none;
    }
    .logo{
      max-width: 12rem;
    }
    .navbar-light .nav-sub-menu .nav-link:hover,
    .navbar-light .nav-sub-menu .nav-link:focus,
    .nav-item.b-nav-dropdown.dropdown.show .nav-link {
      color: #EB5A23 !important;
    }
  }

  // for desktop
  @media only screen and (min-width: 991px) {
    .dropdown:hover .dropdown-menu {
      display: block;
    }
  }

}
</style>
