import axios from "axios"
import Vue from "vue"
import * as axiosHelper from "@/utils/axiosHelper"

Vue.prototype.$http = axios
axios.defaults.baseURL =
	process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_URL : "http://localhost:3000/api"
axios.defaults.headers.Accept = "application/json"
axios.defaults.headers.X_REQUESTED_WITH = "XMLHttpRequest"
axios.defaults.withCredentials = true

axios.interceptors.request.use((config) => {
	return config
})

axios.interceptors.response.use(
	(response) => {
		return Promise.resolve(response)
	},
	(error) => {
		if (error.message === "Network Error") {
			Vue.toasted.show("Check your API server, it may not be running", {
				type: "error",
				duration: 2000,
			})
			return
		}
		if (error.response.status === 400 && error.response.data.error) {
			Vue.toasted.show(error.response.data.error, {
				type: "error",
				duration: 2000,
			})
		} else if (error.response.status === 401) {
			localStorage.setItem("redirect", window.location.pathname)
			axiosHelper.renderLogin()
		} else if (error.response.status === 403) {
			localStorage.clear()
			axiosHelper.renderLogin()
		} else if (error.response.status === 404) {
			axiosHelper.pageNotFound()
		} else if (error.response.data?.message) {
			axiosHelper.printErrorMessage(error.response.data.message)
		} else {
			axiosHelper.somethingWrong()
		}
	},
)
