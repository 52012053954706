// Additional polyfills
import "custom-event-polyfill"
import "url-polyfill"

import Vue from "vue"
import App from "./App"
import router from "./router"
import Vuelidate from "vuelidate"
import store from "./store/index"
import Toasted from "vue-toasted"
import "./plugins/toasts"

import BootstrapVue from "bootstrap-vue"
import VueAnalytics from "vue-analytics"
import moment from "moment"
import globals from "./globals"
import Popper from "popper.js"
import "./plugins/axiosConfig"
import Viewer from "v-viewer"
import "viewerjs/dist/viewer.css"
import VueFormWizard from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
	id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
	router,
})

Vue.use(BootstrapVue)
Vue.prototype.moment = moment
Vue.use(Toasted)
Vue.use(Vuelidate)
Vue.use(Viewer)
Vue.use(VueFormWizard)

Vue.filter("capitalize", (value) =>
	value
		.split("-")
		.map((item) => item.charAt(0).toUpperCase() + item.substring(1))
		.join(" "),
)

Vue.component("ckeditor", {
	template: `<div><textarea :id="id" :value="value"></textarea></div>`,
	props: {
		value: {
			type: String,
		},
		id: {
			type: String,
		},
		height: {
			type: String,
			default: "100",
		},
		toolbar: {
			type: Array,
			default: () => [
				{
					name: "document",
					groups: ["mode", "document", "doctools"],
					items: ["Source", "-", "Save", "Preview", "Print", "-", "Templates"],
				},
				{
					name: "clipboard",
					groups: ["clipboard", "undo"],
					items: ["Cut", "Copy", "Paste", "PasteText", "PasteFromWord", "-", "Undo", "Redo"],
				},
				{
					name: "editing",
					groups: ["find", "selection", "spellchecker"],
					items: ["Find", "Replace", "-", "SelectAll", "-", "Scayt"],
				},
				{
					name: "forms",
					items: ["Form", "Checkbox", "Radio", "TextField", "Textarea", "Select", "simplebutton", "HiddenField"],
				},
				{
					name: "basicstyles",
					groups: ["basicstyles", "cleanup"],
					items: [
						"Bold",
						"Italic",
						"Underline",
						"Strike",
						"Subscript",
						"Superscript",
						"-",
						"CopyFormatting",
						"RemoveFormat",
					],
				},
				{
					name: "paragraph",
					groups: ["list", "indent", "blocks", "align", "bidi"],
					items: [
						"NumberedList",
						"BulletedList",
						"-",
						"Outdent",
						"Indent",
						"-",
						"Blockquote",
						"CreateDiv",
						"-",
						"JustifyLeft",
						"JustifyCenter",
						"JustifyRight",
						"JustifyBlock",
						"-",
						"BidiLtr",
						"BidiRtl",
					],
				},
				{ name: "links", items: ["Link", "Unlink", "Anchor", "FileUpload"] },
				{
					name: "insert",
					items: [
						"Image",
						"ImageUpload",
						"Flash",
						"Table",
						"HorizontalRule",
						"Smiley",
						"SpecialChar",
						"PageBreak",
						"Iframe",
					],
				},
				"/",
				{ name: "styles", items: ["Styles", "Format", "Font", "FontSize"] },
				{
					name: "colors",
					items: ["TextColor", "BGColor", "-", "Mathjax", "Maximize"],
				},
			],
		},
		language: {
			type: String,
			default: "en",
		},
		extraplugins: {
			type: String,
			extraPlugins: "simplebutton,mathjax,accordion",
			default: "simplebutton,mathjax,accordion",
		},
	},
	beforeUpdate() {
		const ckeditorId = this.id
		if (this.value !== CKEDITOR.instances[ckeditorId].getData()) {
			CKEDITOR.instances[ckeditorId].setData(this.value)
		}
	},
	mounted() {
		const ckeditorId = this.id
		const ckeditorConfig = {
			toolbar: this.toolbar,
			language: this.language,
			height: this.height,
			extraPlugins: this.extraplugins,
			removePlugins: "elementspath,exportpdf",
			resize_enabled: false,
			allowedContent: true,
			mathJaxLib: "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML",
			contentsCss: `${process.env.VUE_APP_UI_URL}/ckeditor/customFont/custom-font.css`,
			font_names:
				"Lyon Display Regular;" +
				"Lyon Display Medium;" +
				"Lyon Display Bold;" +
				"Lyon Text Regular;" +
				"Lyon Text Medium;" +
				"Lyon Text Bold;" +
				"Repro Regular;" +
				"Repro Medium;" +
				"Repro Bold;" +
				"DMSans Regular;" +
				"DMSans Medium;" +
				"DMSans Bold;",
			filebrowserBrowseUrl: "/ckeditor/file",
			filebrowserImageBrowseUrl: "/ckeditor/image",
			filebrowserImageUploadUrl: `${process.env.VUE_APP_API_URL}/ckeditor_pictures/create_ckeditor_image`,
			filebrowserUploadUrl: `${process.env.VUE_APP_API_URL}/ckeditor_attachment_files/create_ckeditor_attachment`,
		}

		CKEDITOR.replace(ckeditorId, ckeditorConfig)
		CKEDITOR.instances[ckeditorId].setData(this.value)
		CKEDITOR.instances[ckeditorId].on("change", () => {
			const ckeditorData = CKEDITOR.instances[ckeditorId].getData()
			if (ckeditorData !== this.value) {
				this.$emit("input", ckeditorData)
			}
		})
		CKEDITOR.instances[ckeditorId].on("fileUploadResponse", (evt) => {
			evt.stop()
			let response = null
			let imageUrl = null
			if (evt.data.fileLoader.xhr.response) {
				response = JSON.parse(evt.data.fileLoader.xhr.response)
				imageUrl = process.env.VUE_APP_URL + response.url
			} else {
				response = null
				imageUrl = null
			}
			setTimeout(() => {
				const dialog = CKEDITOR.dialog.getCurrent()
				if (dialog) {
					let urlField = null
					if (response.type === "Image") {
						urlField = dialog.getContentElement("info", "txtUrl")
					} else {
						urlField = dialog.getContentElement("info", "url")
					}
					if (urlField) {
						// Set the image URL in the URL input field
						urlField.setValue(imageUrl)
						alert("Uploaded successfully! Please check Info tab")
					}
				}
			}, 100)
		})
	},
	destroyed() {
		const ckeditorId = this.id
		if (CKEDITOR.instances[ckeditorId]) {
			CKEDITOR.instances[ckeditorId].destroy()
		}
	},
})

// Global RTL flag
Vue.mixin({
	data: globals,
})

window.MyAppConfig = {
	CK_Editor_API_URL: process.env.VUE_APP_URL,
}

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
