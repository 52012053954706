<template>
  <div>
    <div v-if="type === 'dashboard'">
      <div class="gcse-searchbox-only" data-resultsUrl="/search"></div>
    </div>
    <div v-else class="container mt-4">
      <div class="gcse-search"></div>
    </div>
  </div>
</template>

<script>
export default {
	name: "Search",
	props: ["type"],
	mounted() {
		const gcse = document.createElement("script")
		gcse.type = "text/javascript"
		gcse.async = true
		gcse.src = `https://cse.google.com/cse.js?cx=${process.env.VUE_APP_CSE_ID}`
		const s = document.getElementsByTagName("script")[0]
		s.parentNode.insertBefore(gcse, s)
	},
}
</script>

<style lang="scss">
.gsc-search-button-v2 {
  background-color: #000000 !important;
  padding: 6px 15px !important;
  filter: none !important;
  margin: 0 !important;
  background-image: none !important;
  border: none !important;
  @media (max-width: 47.9rem) {
    padding: 14px 14px !important
  }
}
.gsc-search-button {
  background-color: #000000 !important;
}
.gsc-adBlock {
  display: none !important;
}
table.gsc-search-box td {
  border: none;
}
</style>
