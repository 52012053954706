import router from "@/router"
import store from "@/store/index"
import Vue from "vue"

export function renderLogin() {
	Vue.toasted.show("Please login again", { type: "error", duration: 2000 })
	store.dispatch("logOut", false)
	router.push({ name: "signin" })
}

export function restrictedPage() {
	Vue.toasted.show("You are not authorized to do that.", {
		type: "error",
		duration: 2000,
	})
	router.push({ name: "not-authorized" })
}

export function pageNotFound() {
	router.push({ name: "not-found" })
}

export function printErrorMessage(message) {
	Vue.toasted.show(message, {
		type: "error",
		duration: 2000,
	})
}

export function somethingWrong() {
	Vue.toasted.show("Something went wrong. Please retry.", {
		type: "error",
		duration: 2000,
	})
}
