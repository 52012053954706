<template>
  <div class="menu-head">
    <b-navbar-nav>
      <div v-for="menu in siteMenus" :key="menu.id">
        <router-link
          :to ="getMainMenuUrl(menu.path)"
          v-if="checkForCalendarAccess(menu)"
        >
          <b-nav-item-dropdown toggle-class="medium-14" :text="menu.name" :disabled="disabledLink" v-if="menu.sub_menus.length > 0">
            <b-dropdown-item>
              <div class="d-sm-flex flex-wrap align-items-start justify-content-start">
                <div v-if="!disabledLink">
                  <router-link
                    :to ="getMainMenuUrl(menu.path)"
                  >
                    <span class="mbl-dropdown-header medium-14"> {{menu.name}} </span>
                  </router-link>
                </div>
                <div v-for="submenu in menu.sub_menus" :key="submenu.id">
                  <div v-if="submenu.name!==null">
                    <div class="dropdown-header medium-14">{{submenu.name}}</div>
                    <div v-for="link in submenu.menu_items" :key="link.id">
                      <div class="dropdown-item">
                        <router-link
                          :to ="getSubMainMenuUrl(link.path)"
                        >
                          <span class="medium-14"> {{link.name}} </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <span v-else class="nav-link medium-14"> {{menu.name}} </span>
        </router-link>
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import mixin from "@/utils/mixin.js"
import { mapGetters } from "vuex"

export default {
	mixins: [mixin],
	data() {
		return {
			disabledLink: true,
			siteMenus: [],
			loadingData: true,
		}
	},
	computed: {
		...mapGetters(["isAuthenticated", "canEditCms"]),
	},
	created() {
		window.addEventListener("resize", () => {
			this.onPageResize()
		})
	},
	mounted() {
		this.onPageResize()
		this.loadSiteMenus()
		this.$root.$on("menuEvent", () => {
			this.loadSiteMenus()
		})
	},
	methods: {
		onPageResize() {
			this.disabledLink = window.innerWidth > 991
		},
		loadSiteMenus() {
			this.$http.get(`${process.env.VUE_APP_API_URL}/msri_menus`).then((res) => {
				this.siteMenus = res.data.main_menus
				this.loadingData = false
			})
		},
		checkForCalendarAccess(menu) {
			if (menu.path === "/calendar/all-events") {
				if (this.isAuthenticated && this.canEditCms) {
					return true
				}
				return false
			}
			return true
		},
		getMainMenuUrl(menu) {
			return { path: this.getValidUrl(menu) }
		},
		getSubMainMenuUrl(menu) {
			return { path: this.getValidUrl(menu) }
		},
		getValidUrl(url) {
			return url ? `${url}` : "/"
		},
	},
}
</script>

<style scoped lang="scss">
.mbl-dropdown-header {
  display: block;
  padding: 0.3125rem;
  margin-bottom: 5px;
  color: #EB5A23;
  white-space: nowrap;
}
.dropdown-header {
  cursor: no-drop !important;
}
.menu-head {
  width: 100%;
  max-width: 1050px;
	overflow: hidden;
}
</style>
