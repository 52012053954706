<template>
  <div id="app" :key="`load-${loadCount}`">
    <router-view />
  </div>
</template>

<style src="./assets/css/fonts.scss" lang="scss"></style>
<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-cotton.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>
<style src="./custom.scss" lang="scss"></style>
<style src="@/vendor/styles/placeholder-loader.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-input-tag/vue-input-tag.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-simple-calendar/vue-simple-calendar.scss" lang="scss"></style>

<script>
import { mapGetters } from "vuex"

export default {
	name: "App",
	metaInfo: {
		title: "Index",
		titleTemplate: "%s - SLMath",
	},
	data() {
		return {
			loadCount: 0,
		}
	},
	computed: {
		...mapGetters(["isAuthenticated"]),
	},
	watch: {
		$route(_to, from) {
			if (from.name === "search") {
				this.loadCount = this.loadCount + 1
			}
		},
	},
	updated() {
		// Remove loading state
		setTimeout(() => document.body.classList.remove("app-loading"), 1)
	},
}
</script>
