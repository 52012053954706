<template>
  <b-navbar-nav>
    <router-link
      v-if="isAuthenticated"
      :to="{ name: 'people', params: { id: this.authUser.person_id }, query: { reDirectFrom: 'link' }}"
      class="nav-link"
    >
      <i class="fas fa-user typo-color-black" />
      <span class="typo-color-black">My Profile</span>
    </router-link>
    <router-link
      v-if="isAuthenticated && canViewStaffDashboard"
      :to="{ name: 'staff-dashboard' }"
      class="nav-link"
    >
      <i class="fas fa-chalkboard-teacher typo-color-black" />
      <span class="typo-color-black">Staff Dashboard</span>
    </router-link>
    <a v-if="isAuthenticated && canViewSac" :href="fetchUrl('/dashboard/sac')" class="nav-link">
      <i class="fas fa-users typo-color-black" />
      <span class="typo-color-black">SAC</span>
    </a>
    <a v-if="isAuthenticated && canViewHrac" :href="fetchUrl('/dashboard/bpac')" class="nav-link">
      <i class="fas fa-users typo-color-black" />
      <span class="typo-color-black">BPAC</span>
    </a>
    <router-link
      v-if="isAuthenticated && canEditCms"
      :to="{ name: 'page-builder' }"
      class="nav-link"
    >
      <i class="fas fa-stream typo-color-black" />
      <span class="typo-color-black">Page Builder</span>
    </router-link>
    <a v-if="isAuthenticated" href="javascript:;" class="nav-link" @click="signOut()">
      <i class="ion ion-md-log-out typo-color-black" />
      <span class="typo-color-black">Sign out</span>
    </a>
    <a v-else-if="$route.path !== '/signin'" href="javascript:;" class="nav-link" @click="openSignInModal()">
      <i class="fas fa-user typo-color-black" />
      <span class="typo-color-black">Sign in</span>
    </a>
    <b-nav-item href="/donate" class="nav-button">
      <button class="btn btn-primary">DONATE NOW</button>
    </b-nav-item>
  </b-navbar-nav>
</template>
<script>
import { mapGetters } from "vuex"

export default {
	computed: {
		...mapGetters(["isAuthenticated", "authUser", "canViewSac", "canViewHrac", "canViewStaffDashboard", "canEditCms"]),
	},
	methods: {
		openSignInModal() {
			this.$root.$emit("openSigninModal")
		},
		async signOut() {
			const response = await this.$store.dispatch("logOut", true)
			// if (response.status === 204) {
			//   this.$root.$emit('openSigninModal')
			// }
			this.$toasted.global.success("You have successfully logged out")
		},
		fetchUrl: (url) => process.env.VUE_APP_LEGACY_MSRI_WEB_URL + url,
		fetchPeopleUrl() {
			return `${process.env.VUE_APP_LEGACY_MSRI_WEB_URL}/people/${this.authUser.person_id}`
		},
	},
}
</script>

<style lang="scss" scoped>
  .nav-link {
    border: none;
    background: none;
    color: black;
    padding: 10 0;
  }
  @media only screen and (min-width: 991px) {
  .mobile-only {
      display: block;
    }
  }
</style>
