<template>
  <div>
    <router-view v-if="$route.meta.hideNavBar" />
    <div v-else>
      <div class="layout-wrapper layout-1 layout-without-sidenav" :class="$route.path !== '/signin' && 'custom-page-padding'">
        <div class="layout-inner">
          <app-layout-navbar :sidenav-toggle="false" />
          <div>
            <div class="layout-container">
              <div class="layout-content">
                <app-layout-sidenav v-if="false" orientation="horizontal" />
                <div v-if="bannerData !== null && bannerData.visible_to === 'all_pages' && !loadingBanner">
                  <message-banner :banner-data="bannerData" />
                </div>
                <login-as-banner />
                <div class="router-transitions flex-grow-1">
                  <router-view />
                  <span class="bottom-fixed-button pr-2 pb-2">
                    <span class="up-arrow-btn">
                      <i
                        v-b-popover.hover.top
                        title="Go to top"
                        class="fa fa-arrow-circle-up"
                        @click="scrollToTop()"
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-layout-footer />
    </div>
    <b-modal
      id="signInModal"
      ref="signInModal"
      centered
      hide-header
      hide-footer
    >
      <Signin class="modal-container" :halt-redirect="true" />
    </b-modal>
    <b-modal
      id="signUpModal"
      ref="signUpModal"
      centered
      hide-header
      hide-footer
    >
      <Signup class="modal-container" />
    </b-modal>
    <b-modal
      id="forgotPasswordModal"
      ref="forgotPasswordModal"
      centered
      hide-header
      hide-footer
    >
      <ForgotPassword class="modal-container" />
    </b-modal>
    <b-modal
      id="signUpAlertModal"
      ref="signUpAlertModal"
      centered
      hide-footer
      title="Alert"
    >
      <span class="regular-16">
        Your account creation will be finalized once you have successfully verified your email. A verification email will be sent to the email address you provided during the signup process.
      </span>
    </b-modal>
  </div>
</template>

<script>
import LayoutNavbar from "./LayoutNavbar"
import LayoutSidenav from "./LayoutSidenav"
import LayoutFooter from "./LayoutFooter"
import MessageBanner from "@/components/MessageBanner/MessageBanner.vue"
import LoginAsBanner from "@/components/MessageBanner/LoginAsBanner.vue"
import Signin from "../pages/Signin.vue"
import Signup from "../pages/Signup.vue"
import ForgotPassword from "../pages/ForgotPassword.vue"
export default {
	name: "AppLayoutHorizontalSidenav",
	components: {
		"app-layout-navbar": LayoutNavbar,
		"app-layout-sidenav": LayoutSidenav,
		"app-layout-footer": LayoutFooter,
		MessageBanner,
		LoginAsBanner,
		Signin,
		Signup,
		ForgotPassword,
	},
	data() {
		return {
			bannerData: {},
			loadingBanner: true,
		}
	},
	mounted() {
		// Enshure that we have not '.layout-expanded' class on the html element
		this.layoutHelpers._removeClass("layout-expanded")

		this.layoutHelpers.init()
		this.layoutHelpers.update()
		this.layoutHelpers.setAutoUpdate(true)
		this.getBannerDatas()
		this.$root.$on("bannerEvent", () => {
			this.getBannerDatas()
		})

		this.$root.$on("openSigninModal", () => {
			this.$refs.signInModal.hide()
			this.$refs.signInModal.show()
		})
		this.$root.$on("hideSigninModal", () => {
			this.$refs.signInModal.hide()
		})
		this.$root.$on("openSignUpModal", () => {
			this.$refs.signUpModal.hide()
			this.$refs.signUpModal.show()
		})
		this.$root.$on("hideSignUpModal", () => {
			this.$refs.signUpModal.hide()
		})
		this.$root.$on("openSignUpAlertModal", () => {
			this.$refs.signUpAlertModal.show()
		})
		this.$root.$on("openForgotPasswordModal", () => {
			this.$refs.forgotPasswordModal.hide()
			this.$refs.forgotPasswordModal.show()
		})
		this.$root.$on("hideForgotPasswordModal", () => {
			this.$refs.forgotPasswordModal.hide()
		})
	},
	beforeDestroy() {
		this.layoutHelpers.destroy()
	},
	methods: {
		getBannerDatas() {
			this.$http.get(`${process.env.VUE_APP_API_URL}/msri_banners/show_visible_banner`).then((res) => {
				this.bannerData = res.data
				this.loadingBanner = false
			})
		},
		scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			})
		},
	},
}
</script>

<style lang="scss">
.custom-page-padding {
  padding-bottom: 8rem !important;
}
.bottom-fixed-button{
  position: fixed;
  bottom: 0px;
  padding-top: 10px;
  //width: 100%;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.up-arrow-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: #FFFFFF;
  border-radius: 1.25rem;
  z-index: 60;
  cursor: pointer;

  &:hover {
    i {
      color: #EB5A23;
    }
  }
  &:last-child {
    margin-left: 1rem;
  }
  i {
    font-size: 2.25rem;
    transition: all .3s ease;
  }
}
</style>
