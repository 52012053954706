<template>
  <div class="primary-background pb-5">
    <div class="container pt-5">
      <b-card class="col-lg-6 col-md-12 m-auto border">
        <b-form v-if="show" @submit="onSubmit" @reset="onReset">
          <h3 class="text-center mt-3 mb-5 lyon-display-medium-24">Login to SLMath</h3>
          <b-form-group
            id="input-group-1"
            label="Email address"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Enter email"
              required
            />
          </b-form-group>

          <b-form-group id="input-group-2" label="Password" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.password"
              placeholder="Password"
              type="password"
              required
            />
          </b-form-group>
          <div class="social-btn-wrapper d-flex">
            <div class="social-btn">
              <b-form-checkbox v-model="form.remember_me" class="mb-2">Remember me</b-form-checkbox>
            </div>
            <div class="social-btn">
              <a href="javascript:;" class="nav-link" @click="openForgotPasswordModal()">
                <span class="typo-color-blue">
                  <i class="fa fa-key" />
                  Forgot password?
                </span>
              </a>
            </div>
          </div>
          <b-button type="submit" variant="primary" :disabled="loading" block>
            <b-spinner v-if="loading" /> SIGN IN
          </b-button>
          <div class="m-3 text-center"><small class="text-muted">or</small></div>
          <div class="social-btn-wrapper d-flex">
            <button type="button" class="social-btn border" @click="signInWithGoogle()">
              <div class="d-flex justify-content-center">
                <img src="/img/google-icon.png" alt="arrow-right" class="btn-arrow">
                <div class="pl-2 pt-1">Sign in with Google</div>
              </div>
            </button>
            <button type="button" class="social-btn border" @click="signInWithOrcid()">
              <div class="d-flex justify-content-center">
                <img src="/img/ocid-icon.png" alt="arrow-right" class="btn-arrow">
                <div class="pl-2 pt-1">Sign in with ORCID</div>
              </div>
            </button>
          </div>
        </b-form>
        <div class="mt-5 mb-3 d-flex justify-content-center">
          <!-- <router-link :to="{ name: 'signup'}">
            First time here? Create your account
          </router-link> -->
          <button type="button" class="nav-link" @click="openSignupModal">
            <span class="typo-color-blue">
              <i class="fas fa-user-plus" />
              First time here? Create your account
            </span>
          </button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import mixin from "@/utils/mixin.js"

export default {
	name: "Signin",
	metaInfo: {
		titleTemplate: (title) => {
			return `${title === "Index" ? "Sign in" : title} - SLMath`
		},
	},
	mixins: [mixin],
	props: ["haltRedirect"],
	data() {
		return {
			form: {
				email: "",
				password: "",
				remember_me: false,
			},
			show: true,
			legacyRedirectUrl: "",
			loading: false,
		}
	},
	async created() {
		if (this.$route.query.redirect_url !== undefined) {
			await this.signOut()
			localStorage.setItem("redirect", this.$route.query.redirect_url)
		}
		if (this.haltRedirect) {
			localStorage.setItem("redirect", window.location.pathname)
		}
	},
	methods: {
		openForgotPasswordModal() {
			this.$root.$emit("hideSigninModal")
			this.$root.$emit("openForgotPasswordModal")
		},
		openSignupModal() {
			this.$root.$emit("hideSigninModal")
			this.$root.$emit("openSignUpModal")
		},
		signOut() {
			this.$store.dispatch("logOut", false)
		},
		async onSubmit(event) {
			event.preventDefault()
			if (this.form.email !== "" && this.form.password !== "") {
				this.loading = true
				const response = await this.$store.dispatch("logIn", {
					authData: this.form,
					haltRedirect: this.haltRedirect,
					legacyRedirectUrl: this.legacyRedirectUrl,
				})
				if (response && response.status === 200) {
					this.$root.$emit("hideSigninModal")
					this.loading = false
				}
				this.loading = false
			}
		},
		onReset(event) {
			event.preventDefault()
			// Reset our form values
			this.form.email = ""
			this.form.name = ""
			this.form.food = null
			this.form.checked = []
			// Trick to reset/clear native browser form validation state
			this.show = false
			this.loading = false
			this.$nextTick(() => {
				this.show = true
			})
		},
		signInWithGoogle() {
			// if(!this.haltRedirect){
			//   this.$store.dispatch('updateCurrentRedirectUrl', localStorage.getItem('LS_ROUTE_KEY'))
			// }
			location.replace(`${process.env.VUE_APP_API_URL}/auth/google_oauth2`)
		},
	},
}
</script>

<style scoped lang='scss'>
  .social-btn {
    width: 50%;
    height: 40px;
    background: #fff;
    img {
      height: 30px
    }
  }
  .social-btn:first-child{
    margin-right: 5px;
  }
  .social-btn:last-child{
    margin-right: 5px;
    text-align: right;
  }
  @media only screen and (max-width: 550px) {
    .social-btn-wrapper {
      flex-direction: column;
      margin: 0;
      .social-btn {
        width: 100%;
        margin: 0;
      }
      .social-btn:last-child{
        margin-top: 10px;
        text-align: left;
      }
    }
  }
  .nav-link {
    border: none;
    background: none;
    color: black;
    padding: 0 0;
  }
</style>
