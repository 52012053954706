<template>
  <div>
    <div>
      <custom-loader v-if="loader" />
      <b-form v-else @submit="onSubmit">
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Support*">
            <ckeditor
              id="footerSupportId"
              v-model="modalData.footer_support"
              :class="{
                'is-invalid': $v.modalData.footer_support.$error,
                'is-valid': !$v.modalData.footer_support.$invalid,
              }"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_support.required">
              Footer Support is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Contact Address*">
            <b-input
              v-model="modalData.footer_contact_address"
              :class="{
                'is-invalid': $v.modalData.footer_contact_address.$error,
                'is-valid': !$v.modalData.footer_contact_address.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_contact_address.required">
              Footer Contact Address is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Contact Phone*">
            <b-input
              v-model="modalData.footer_contact_phone"
              :class="{
                'is-invalid': $v.modalData.footer_contact_phone.$error,
                'is-valid': !$v.modalData.footer_contact_phone.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_contact_phone.required">
              Footer Contact Phone is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Contact Fax*">
            <b-input
              v-model="modalData.footer_contact_fax"
              :class="{
                'is-invalid': $v.modalData.footer_contact_fax.$error,
                'is-valid': !$v.modalData.footer_contact_fax.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_contact_fax.required">
              Footer Contact Fax is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Contact Mail Address*">
            <b-input
              v-model="modalData.footer_contact_mail_address"
              :class="{
                'is-invalid': $v.modalData.footer_contact_mail_address.$error,
                'is-valid': !$v.modalData.footer_contact_mail_address.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_contact_mail_address.required">
              Footer Contact  Mail Address is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Mailing Title*">
            <b-input
              v-model="modalData.footer_mailing_title"
              :class="{
                'is-invalid': $v.modalData.footer_mailing_title.$error,
                'is-valid': !$v.modalData.footer_mailing_title.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_mailing_title.required">
              Footer Mailing Title is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Mailing Text*">
            <ckeditor
              id="footerMailingTextId"
              v-model="modalData.footer_mailing_text"
              :class="{
                'is-invalid': $v.modalData.footer_mailing_text.$error,
                'is-valid': !$v.modalData.footer_mailing_text.$invalid,
              }"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_mailing_text.required">
              Footer Mailing Text is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Mailing Join Label*">
            <b-input
              v-model="modalData.footer_mailing_join_label"
              :class="{
                'is-invalid': $v.modalData.footer_mailing_join_label.$error,
                'is-valid': !$v.modalData.footer_mailing_join_label.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_mailing_join_label.required">
              Footer Mailing Join Label is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Mailing Join URL*">
            <b-input
              v-model="modalData.footer_mailing_join_url"
              :class="{
                'is-invalid': $v.modalData.footer_mailing_join_url.$error,
                'is-valid': !$v.modalData.footer_mailing_join_url.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_mailing_join_url.required">
              Footer Mailing Join URL is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Facebook URL*">
            <b-input
              v-model="modalData.footer_facebook_url"
              :class="{
                'is-invalid': $v.modalData.footer_facebook_url.$error,
                'is-valid': !$v.modalData.footer_facebook_url.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_facebook_url.required">
              Footer Facebook URL is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Twitter URL*">
            <b-input
              v-model="modalData.footer_twitter_url"
              :class="{
                'is-invalid': $v.modalData.footer_twitter_url.$error,
                'is-valid': !$v.modalData.footer_twitter_url.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_twitter_url.required">
              Footer Twitter URL is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Instagram URL*">
            <b-input
              v-model="modalData.footer_instagram_url"
              :class="{
                'is-invalid': $v.modalData.footer_instagram_url.$error,
                'is-valid': !$v.modalData.footer_instagram_url.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_instagram_url.required">
              Footer Instagram URL is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Copyright Text*">
            <b-input
              v-model="modalData.footer_copyright_text"
              :class="{
                'is-invalid': $v.modalData.footer_copyright_text.$error,
                'is-valid': !$v.modalData.footer_copyright_text.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_copyright_text.required">
              Footer Copyright Text is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <b-form-row class="pt-3">
          <b-form-group class="col" label="Footer Privacy Policy URL*">
            <b-input
              v-model="modalData.footer_privacy_policy_url"
              :class="{
                'is-invalid': $v.modalData.footer_privacy_policy_url.$error,
                'is-valid': !$v.modalData.footer_privacy_policy_url.$invalid,
              }"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.modalData.footer_privacy_policy_url.required">
              Footer Privacy Policy URL is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-row>
        <div class="custom-card mb-3">
          <label>Footer logo</label>
          <b-card class="light-style">
            <div class="table-responsive">
              <table class="table">
                <tbody>
                  <tr>
                    <td colspan="7">
                      <div class="text-center p-5">
                        <label class="btn btn-default">
                          <input
                            id="footerLogo"
                            ref="footerLogo"
                            type="file"
                            accept="image/*"
                            @change="uploadImage($event)"
                          >
                        </label>
                      </div>
                    </td>
                  </tr>
                  <div v-if="image_url">
                    <tr class="row">
                      <td class="col-md-5 text-center">
                        <img
                          :src="image_url"
                          width="100"
                          height="auto"
                          onerror="this.onerror=null;this.src='/img/empty_program1.png';"
                        >
                      </td>
                      <td class="col-md-5 uploaded-image">
                        {{ image_name }}
                      </td>
                      <td class="col-md-2 uploaded-image">
                        <i
                          ref="uploadImage"
                          v-b-tooltip.hover.left="'Delete Uploaded Image'"
                          class="fas fa-trash cursor-pointer custom-fa-delete-color"
                          @click="removeImage()"
                        />
                      </td>
                    </tr>
                  </div>
                </tbody>
              </table>
            </div>
          </b-card>
        </div>
        <div class="custom-card mb-4">
          <label>Footer Quick Link*</label>
          <b-card>
            <b-form-row v-for="(input, k) in modalData.footer_quick_link" :key="k" class="">
              <b-form-group
                class="col-md-11"
              >
                <multiselect
                  v-model="input.menu"
                  :class="{
                    'is-invalid':
                      $v.modalData.footer_quick_link.$each[k].menu.$error,
                    'is-valid':
                      !$v.modalData.footer_quick_link.$each[k].menu
                        .$invalid,
                  }"
                  :options="menuDropdownList"
                  :show-labels="false"
                  placeholder="Select Menu"
                  label="name"
                  track-by="id"
                />
                <b-form-invalid-feedback
                  v-if="!$v.modalData.footer_quick_link.$each[k].menu.required"
                >
                  Menu is required.
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="col-md-1 remove-menu-button">
                <i
                  v-show="k || (!k && modalData.footer_quick_link.length > 1)"
                  v-b-tooltip.hover.top="'Remove column'"
                  class="fas fa-minus-square cursor-pointer"
                  style="font-size: 20px; color: red"
                  @click="remove(k)"
                />
              </div>
            </b-form-row>
            <div class="mb-5">
              <i
                v-b-tooltip.hover.top="'Add more column'"
                class="fa fa-plus-square cursor-pointer"
                style="font-size: 25px"
                @click="add()"
              />
            </div>
          </b-card>
        </div>
        <hr>
        <div slot="modal-footer" class="w-100">
          <b-btn
            size="md"
            type="submit"
            class="float-right btn btn-success btn-md"
          >
            {{ modalData.id ? 'UPDATE' : 'CREATE' }}
          </b-btn>
          <b-btn
            size="md"
            class="float-right mr-2 btn btn-danger"
            @click="hideModal"
          >
            CLOSE
          </b-btn>
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import { required } from "vuelidate/lib/validators"
import CustomLoader from "@/components/CommonPages/CustomLoader.vue"
import Multiselect from "vue-multiselect"

export default {
	name: "FooterEdit",
	metaInfo: {
		title: "Footer Edit",
	},
	components: {
		Multiselect,
		CustomLoader,
	},
	data() {
		return {
			modalData: {
				footer_quick_link: [{ menu: "" }],
			},
			menuDropdownList: [],
			loader: true,
			remote_image_name: null,
			remote_image_url: null,
			image_name: null,
			image_url: null,
			file: null,
		}
	},
	computed: {
		...mapGetters(["canEditCms"]),
	},
	created() {
		this.loadMenuDropdownList()
		this.fetchFooterData()
	},
	methods: {
		add() {
			if (this.modalData.footer_quick_link.length < 8) {
				this.modalData.footer_quick_link.push({ menu: "" })
			} else {
				this.$toasted.global.error("limit exceeded (max 8 menus only).")
			}
		},
		remove(index) {
			this.modalData.footer_quick_link.splice(index, 1)
		},
		populateImageField() {
			const dt = new DataTransfer()
			if (!!this.image_name) {
				const fileName = this.image_name
				this.file = new File([fileName], fileName)
				dt.items.add(this.file)
				if (this.$refs.footerLogo !== undefined) {
					this.$refs.footerLogo.files = dt.files
				}
			}
		},
		uploadImage(e) {
			var selectedFile = e.target.files[0]
			this.file = selectedFile
			this.image_name = this.file.name
			this.image_url = URL.createObjectURL(this.file)
		},
		removeImage() {
			if (confirm("Do you really want to delete?")) {
				if (this.remote_image_name !== this.file.name) {
					this.file = null
					this.image_name = this.remote_image_name
					this.image_url = this.remote_image_url
					if (this.image_name) {
						this.populateImageField()
					} else {
						document.getElementById("footerLogo").value = ""
					}
					return
				} else if (this.remote_image_name === this.file.name) {
					this.$http
						.post(process.env.VUE_APP_API_URL + `/footer_pages/remove_image`, {
							id: this.modalData.id,
						})
						.then((res) => {
							if (res === undefined) {
								//this.$toasted.global.error("Process has been failed.");
							} else if (res.data.error !== undefined) {
								this.$toasted.global.error(res.data.error)
							} else {
								this.$toasted.global.success("Image Removed Successfully")
								this.image_url = null
								this.image_name = null
								document.getElementById("footerLogo").value = ""
							}
							this.loading = false
						})
						.catch((err) => {})
				}
			}
		},
		loadMenuDropdownList() {
			this.loader = true
			this.$http
				.get(process.env.VUE_APP_API_URL + "/msri_menu_quick_links/")
				.then((res) => {
					this.menuDropdownList = res.data.menu_list
					this.loader = false
				})
				.catch((err) => {
					//this.$toasted.global.error('Something went wrong, please try again.')
					this.loader = false
				})
		},
		fetchFooterData() {
			this.loader = true
			this.$http.get(process.env.VUE_APP_API_URL + "/footer_pages").then((res) => {
				if (res.data.footer_page !== null) {
					this.modalData = res.data.footer_page
					if (res.data.image_url !== null) {
						;(this.image_url = res.data.image_url),
							(this.image_name = res.data.image_name),
							(this.remote_image_name = res.data.image_name),
							(this.remote_image_url = res.data.image_url)
					}
					this.populateImageField()
				}
				this.loader = false
			})
		},
		onSubmit(event) {
			this.$v.$touch()
			event.preventDefault()
			if (this.$v.$invalid) {
				this.$toasted.global.error("Please fill out the required fields.")
			} else {
				this.loader = true
				const formData = new FormData()
				Object.entries(this.modalData).map((item) => {
					if (item[0] !== "footer_quick_link") {
						formData.append(`[${item[0]}]`, item[1])
					} else {
						for (var linkArray of item[1]) {
							Object.entries(linkArray.menu).map((item1) => {
								formData.append(`footer_quick_link[]menu[${item1[0]}]`, item1[1])
							})
						}
					}
				})
				if (!!this.file && this.remote_image_name != this.file.name) {
					formData.append("new_image", this.file)
				}
				if (!this.modalData.id) {
					this.$http
						.post(process.env.VUE_APP_API_URL + "/footer_pages", formData)
						.then((res) => {
							if (res === undefined) {
								//that.$toasted.global.error("Action has been failed");
							} else if (res.data.error !== undefined) {
								this.$toasted.global.error(res.data.error)
							} else if (res.status === 403) {
								this.$toasted.global.error("Please Sign in again")
							} else {
								this.successMessage("Created")
								this.hideModal()
							}
						})
						.catch((e) => {
							this.errorMessage("Creating")
						})
				} else {
					this.$http
						.patch(process.env.VUE_APP_API_URL + `/footer_pages/${this.modalData.id}`, formData)
						.then((res) => {
							if (res === undefined) {
								//that.$toasted.global.error("Action has been failed");
							} else if (res.data.error !== undefined) {
								this.$toasted.global.error(res.data.error)
							} else if (res.status === 403) {
								this.$toasted.global.error("Please Sign in again")
							} else {
								this.successMessage("Updated")
								this.hideModal()
							}
						})
						.catch((e) => {
							this.errorMessage("Updating")
						})
				}
				this.loader = false
			}
		},
		deleteFooter() {
			this.$http
				.delete(process.env.VUE_APP_API_URL + `/footer_pages/${this.modalData.id}`)
				.then((res) => {
					if (res === undefined) {
						//that.$toasted.global.error("Action has been failed");
					} else if (res.data.error !== undefined) {
						this.$toasted.global.error(res.data.error)
					} else if (res.status === 403) {
						this.$toasted.global.error("Please Sign in again")
					} else {
						this.successMessage("Deleted")
						this.hideModal()
					}
				})
				.catch((e) => {
					this.errorMessage("Deleting")
				})
		},
		successMessage(substituteText) {
			this.$toasted.global.success(`Successfully Footer ${substituteText}`)
		},
		errorMessage(substituteText) {
			this.$toasted.global.warning(`Error while Footer ${substituteText}`)
		},
		hideModal() {
			this.$root.$emit("footerModalEvent")
		},
	},
	validations: {
		modalData: {
			footer_quick_link: {
				$each: {
					menu: {
						required,
					},
				},
			},
			footer_support: {
				required,
			},
			footer_contact_address: {
				required,
			},
			footer_contact_phone: {
				required,
			},
			footer_contact_fax: {
				required,
			},
			footer_contact_mail_address: {
				required,
			},
			footer_mailing_title: {
				required,
			},
			footer_mailing_text: {
				required,
			},
			footer_mailing_join_label: {
				required,
			},
			footer_mailing_join_url: {
				required,
			},
			footer_facebook_url: {
				required,
			},
			footer_twitter_url: {
				required,
			},
			footer_copyright_text: {
				required,
			},
			footer_privacy_policy_url: {
				required,
			},
			footer_instagram_url: {
				required,
			},
		},
	},
}
</script>

<style scoped lang="scss">
.remove-menu-button {
  display: flex;
  align-items: center;
  @media only screen and (max-width: 64rem) {
    align-items: none;
  }
}
.uploaded-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
