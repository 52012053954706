<template>
  <div>
    <div class="container pt-5">
      <b-card class="col-lg-6 col-md-12 m-auto border" v-if="showBanner">
        <div>
          <div class="regular-24 text-center">Password Reset Instructions Have Been Emailed to You</div>
          <div class="regular-16 text-center">Please follow the link in your email to reset your password.</div>
        </div>
      </b-card>

      <b-card v-else class="col-lg-6 col-md-12 m-auto border">
        <b-form @submit="onSubmit" autocomplete="off">
          <h3 class="text-center mt-3 mb-5 lyon-display-medium-24">Password Recovery</h3>
          <b-form-group label="Email Address*">
            <b-input
              type="email"
              maxlength="50"
              v-model="forgotPasswordForm.email"
              :class="{'is-invalid': $v.forgotPasswordForm.email.$error, 'is-valid': !$v.forgotPasswordForm.email.$invalid}"
              placeholder="Email Address*"
              autocomplete="off"
              data-lpignore="true"
            />
            <b-form-invalid-feedback v-if="!$v.forgotPasswordForm.email.required || !$v.forgotPasswordForm.email.email">
              Please provide a valid email address.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <div id="recaptcha"></div>
          </b-form-group>

          <b-button type="submit" variant="primary" :disabled="!recaptchaVerified || loading" block>
            <b-spinner v-if="loading" />SEND RESET EMAIL
          </b-button>
        </b-form>
        <div class="m-3 text-center"><small class="text-muted">or</small></div>
        <div class="mt-4 mb-3 text-center d-flex justify-content-center">
          <button type="button" class="nav-link" @click="openLoginModal">
            <span class="typo-color-blue">
              <i class="fas fa-user" />
              Login to Your SLMath Account
            </span>
          </button>
        </div>
      </b-card>
    </div>
</div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"

export default {
	name: "ForgotPassword",
	metaInfo: {
		title: "Forgot Password",
	},
	data() {
		return {
			forgotPasswordForm: {
				email: "",
			},
			showBanner: false,
			recaptchaVerified: false,
			token: "",
			loading: false,
		}
	},
	validations: {
		forgotPasswordForm: {
			email: {
				required,
				email,
			},
		},
	},
	mounted() {
		this.loadRecaptcha()
	},
	methods: {
		openLoginModal() {
			this.$root.$emit("hideForgotPasswordModal")
			this.$root.$emit("openSigninModal")
		},
		async loadRecaptcha() {
			await grecaptcha.ready(() => {
				grecaptcha.execute(process.env.VUE_APP_RECAPTCHA, { action: "resetpassword" }).then((token) => {
					this.token = token
					this.recaptchaVerified = true
				})
			})
		},
		onSubmit(event) {
			event.preventDefault()
			this.$v.$touch()
			this.loadRecaptcha()
			if (!this.$v.$invalid && this.recaptchaVerified) {
				this.loading = true
				this.$http
					.post(`${process.env.VUE_APP_API_URL}/forgot_password`, {
						email: this.forgotPasswordForm.email,
						"g-recaptcha-response-data": this.token,
					})
					.then((res) => {
						if (res !== undefined) {
							if (res.data.errors !== undefined) {
								this.$toasted.global.error(res.data.errors)
								// this.$router.replace({name: "signin"})
								this.$root.$emit("openSigninModal")
								this.$root.$emit("hideForgotPasswordModal")
							} else {
								this.$root.$emit("hideForgotPasswordModal")
								this.$toasted.global.success(
									"An email has been sent to you with instructions on how to reset your password.",
								)
								this.showBanner = true
							}
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
	},
}
</script>

<style scoped lang='scss'>
  .nav-link {
    border: none;
    background: none;
    color: black;
    padding: 0 0;
  }
</style>