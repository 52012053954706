<template>
  <div v-if="isAuthenticated && tempAuthUser">
    <div class="banner-sec" v-if="tempAuthUser.email">
      <b-card class="px-3 py-1" text-variant="black">
        <span><i class="fa fa-info-circle"></i></span>
        <span class="mr-3 regular-18 typo-color-red">
          You're viewing this site as: {{ authUser.name }}.
          <span class="cursor-pointer typo-color-blue" @click="signInAs()">Click here</span> to return to your account.
        </span>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoginAsBanner',
  computed: {
    ...mapGetters(['isAuthenticated', 'authUser', 'tempAuthUser']),
  },
  methods: {
    signInAs() {
      this.$store.dispatch('signInAs', { userId: this.tempAuthUser.id, adminUserId: this.tempAuthUser.id })
      this.$store.dispatch('updateTempAuthData', { email: '' })
      document.cookie = "viewAs=false;domain=.slmath.org;path=/;";
     }
  }
}
</script>
<style scoped lang="scss">
@media screen and (min-width: 600px) {
  h6 { padding-top: 19px; }
}
@media screen and (max-width: 600px) {
  h6 { padding-top: 5px; margin: 0; }
}
img{
  width: 200px;
}
.card-body{
  text-align: center;
  margin-left: 0.7rem;
  padding: 0.5rem;
}
.banner-sec {
  background-color: #FCFAF8;
}
</style>
